body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* font-family: 'Roboto'; */
}

.mantine-Breadcrumbs-separator {
    margin-top: -1px;
}

.mantine-Badge-inner {
    margin-top: 2px;
}

.mantine-SegmentedControl-root {
    margin-top: -2px;
}

.custom-mantine-avatar-placeholder {
    background-color: transparent;
}

.custom-mantine-avatar-placeholder-gradient {
    background-image: linear-gradient(45deg, #8d9297 0%, #2f4a56 100%);
}

.custom-mantine-badge-group-up > svg {
    margin-top: -2px !important;
}

.custom-mantine-badge-group-down > svg {
    margin-top: 2px !important;
}

/* .custom-mantine-table, .custom-mantine-table > * > * > * {
    border: 1px solid #DEE2E6;
    border-radius: 4px;
} */

.custom-mantine-table > thead > tr:first-child > th {
    text-align: center;
}

.custom-mantine-accordion > div > button {
    height: 46px;
}

.custom-antd-rate-small > li > div > div > span > svg {
    width: 14px;
}

.custom-ant-tabs-nav-list > .ant-tabs-nav > .ant-tabs-nav-wrap > .ant-tabs-nav-list {
    width: 100%;
}

.custom-ant-tabs-nav-list-2
    > .ant-tabs-nav
    > .ant-tabs-nav-wrap
    > .ant-tabs-nav-list
    > .ant-tabs-tab:nth-child(2) {
    right: 0;
    position: absolute;
}

.custom-ant-tabs-nav-list-3
    > .ant-tabs-nav
    > .ant-tabs-nav-wrap
    > .ant-tabs-nav-list
    > .ant-tabs-tab:nth-child(3) {
    right: 0;
    position: absolute;
}

.custom-ant-tabs-nav-list-4
    > .ant-tabs-nav
    > .ant-tabs-nav-wrap
    > .ant-tabs-nav-list
    > .ant-tabs-tab:nth-child(4) {
    right: 0;
    position: absolute;
}

.custom-ant-tabs-nav-list-5
    > .ant-tabs-nav
    > .ant-tabs-nav-wrap
    > .ant-tabs-nav-list
    > .ant-tabs-tab:nth-child(5) {
    right: 0;
    position: absolute;
}

.custom-ant-tabs-nav-list-6
    > .ant-tabs-nav
    > .ant-tabs-nav-wrap
    > .ant-tabs-nav-list
    > .ant-tabs-tab:nth-child(6) {
    right: 0;
    position: absolute;
}

.custom-ant-tabs-nav-list-7
    > .ant-tabs-nav
    > .ant-tabs-nav-wrap
    > .ant-tabs-nav-list
    > .ant-tabs-tab:nth-child(7) {
    right: 0;
    position: absolute;
}

.custom-ant-tabs-nav-list-8
    > .ant-tabs-nav
    > .ant-tabs-nav-wrap
    > .ant-tabs-nav-list
    > .ant-tabs-tab:nth-child(8) {
    right: 0;
    position: absolute;
}

.ant-table-tbody > tr.ant-table-row-selected > td {
    background-color: #f1f3f5;
}

.ant-table-tbody > tr.ant-table-row-selected:hover > td {
    background-color: #fafafa;
}
