.login__container{
    height: 100vh;
    padding: 0;
}

.login__wrapper{
    height: 100%;
}

.login__image{
        background-size: cover;
        background: url(/public/assets/image-background.png) no-repeat top right;
}
